import { Component } from 'react';
// import '../../style.css';

class Developer extends Component {
	render() {
		return (
			<>
			<div className='container'>
				<h2>Dev: {this.props.name}!</h2>
				<h4>Idade: {this.props.age}</h4>
				<h3>País: {this.country}</h3>
			</div>
			</>
		)
	}
}

export default Developer;