import logo from './logo.svg';
import './App.css';
import Developer from './components/developer/Developer.jsx';
import './style.css';

function App() {
  return (
    <div>
      <Developer name="Calebe" age={21} country="Brasil"/>
      <Developer name='Matheus' age={24} country="Turquia"></Developer>
      <Developer name='Judas' age={55} country='Nepal'/>
      {/* <h1 className='container'>hhhii</h1> */}
    </div>
  );
}

export default App;